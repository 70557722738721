<template>
    <section class="ecommerce-application">
        <b-row class="d-md-flex justify-content-center">
            <b-col cols="12" md="8" class="mb-5">
                <b-row>
                    <b-col cols="12" class="d-flex justify-content-end mb-1 mt-2">
                        <b-button size="sm" title="Save" variant="outline-secondary" class="mr-1" @click="_saveTemplete">
                            <img width="24" :src="require('@/assets/images/app-icons/save-48.png')" />
                        </b-button>
                        <b-button size="sm" title="Download" variant="outline-secondary" class="mr-1" @click="_downloadTemplate">
                            <img width="24" :src="require('@/assets/images/app-icons/icons8-download-48.png')" />
                        </b-button>
                        <b-button size="sm" title="Copy" variant="outline-secondary" @click="_copy(copyText)">
                            <img width="24" :src="require('@/assets/images/app-icons/icons8-copy-48.png')" />
                        </b-button>
                    </b-col>
                    <b-col cols="12">
                        <quill-editor
                            id="blog-content"
                            v-model="templateText"
                            :options="snowOption"
                        />
                        <b-textarea id="copyInput" :value="copyText" style="height: 1px;width:1px;border: none; background: transparent;color: transparent;overflow: hidden;resize: none;padding: 0 0 0 0;" readonly />
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </section>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from '@/utilities'
import { showErrorNotification, showLoader, showSuccessNotification, hideLoader } from '@/@core/comp-functions/ui/app'
import { quillEditor } from 'vue-quill-editor'
import jwtDefaultConfig from '@/@core/auth/jwt/jwtDefaultConfig'

export default {
    name: 'GenerateAiTemplate',
    components: {
        quillEditor
    },
    props: {

    },

    data: () => ({
        loading: false,
        form: {},
        templateText: null,
        copyText: null,
        savedTemplate: {},
        snowOption: {
            theme: 'snow',
        },
    }),

    computed: {
        ...mapGetters('aiTemplate', [
            'aiTemplatesLoaded',
            'aiTemplateBySlug',
            'aiGeneratedTemplateById',
        ]),
        generatedId: {
            get(){
                return this.$route.params.id
            }
        },
        generatedTemplate: {
            get(){
                if(isEmpty(this.generatedId)) return null;
                const generatedTemplate = this.aiGeneratedTemplateById(this.generatedId)
                return generatedTemplate
            }
        },
    },
    mounted() {
        if(isEmpty(this.generatedTemplate)){
            this.getAiGeneratedTemplate(this.$route.params.id)
        }
    },
    methods: {
        isEmpty,
        ...mapActions('aiTemplate', [
            'getAiGeneratedTemplate',
            'saveTemplate',
        ]),
        _saveTemplete(){
            this.loading = true
            showLoader()
            const payLoad = {
                text: this.templateText,
                id: this.generatedId,
            }
            this.saveTemplate(payLoad).then(r => {
                if (r._statusCode === 200) {
                    const {saved_template, copy_template} = r.responseData
                    this.templateText = saved_template
                    this.copyText = copy_template
                    showSuccessNotification(this, "Template saved successfully!")
                }else if (r._statusCode === 201) {
                    showErrorNotification(this, r.message)
                }else {
                    showErrorNotification(this, r.message || "Something went wrong!")
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                showErrorNotification(this, e.message || "Something went wrong!")
                this.loading = false
                hideLoader()
            });
        },
        _downloadTemplate(){
            this.loading = true
            showLoader()
            const payLoad = {
                text: this.templateText,
                id: this.generatedId,
            }
            this.saveTemplate(payLoad).then(r => {
                if (r._statusCode === 200) {
                    const {pdf_url} = r.responseData
                    if(!isEmpty(pdf_url)){
                        window.open(jwtDefaultConfig.baseEndpoint+pdf_url, '_blank', 'noopener, noreferrer');
                    }
                }
                this.loading = false
                hideLoader()
            }).catch((error) => {
                this.loading = false
                hideLoader()
            });
        },
        _copy(data){
            const textarea = document.createElement('textarea');
            textarea.textContent = data;
            document.body.appendChild(textarea);

            const selection = document.getSelection();
            const range = document.createRange();
            range.selectNode(textarea);
            selection.removeAllRanges();
            selection.addRange(range);

            try {
                if(document.execCommand('copy')){
                    showSuccessNotification(this, 'Copied to clipboard');
                }else{
                    showErrorNotification(this, "Failed to copy");
                }
            } catch (err) {
                showErrorNotification(this, "Failed to copy");
            }
            document.body.removeChild(textarea);
            selection.removeAllRanges();
        },
    },
    watch: {
        generatedTemplate: {
            handler(template){
                if(!isEmpty(template)){
                    this.templateText = !isEmpty(template.saved_template) ? template.saved_template : template.template
                    this.copyText = template.copy_template
                }
            }
        }
    }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>